import type { StateCreator } from 'zustand'

import type { AllSlices } from '../types'
import type { CasinoCategory,CasinoSlice } from '../types/casino'

export const createCasinoSlice: StateCreator<
  AllSlices,
  [],
  [],
  CasinoSlice
> = set => ({
  categories: [],
  setCategories: (categories: CasinoCategory[]) =>
    set((state: AllSlices) => ({
      casino: { ...state.casino, categories },
    })),
})
